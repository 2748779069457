@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

.ReactCollapse--collapse {
  transition: height 500ms;
}

* {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.border-outline-none {
  border: none !important;
  outline: none !important;
}
